import React, { useState, useEffect } from 'react';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, Select, MenuItem } from '@mui/material';
import FormSidebar from './FormSidebar';
import FormDetails from './FormDetails';
import AddFieldDialog from './AddFieldDialog';
import FormsAPI from './FormsAPI'; // Updated import

const FormManager = () => {
    const [forms, setForms] = useState([]);
    const [selectedForm, setSelectedForm] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [isAddFieldDialogOpen, setIsAddFieldDialogOpen] = useState(false);
    const [availableFields, setAvailableFields] = useState([]);
    const [projects, setProjects] = useState([]); // List of projects
    const [selectedProject, setSelectedProject] = useState('');
    const [isProjectDialogOpen, setIsProjectDialogOpen] = useState(false);

    // Fetch forms and projects on initial load
    useEffect(() => {
        FormsAPI.getForms().then((response) => setForms(response.data.forms));
        FormsAPI.getProjects().then((response) => setProjects(response.data.projects)); // Fetch projects
    }, []);

    // Fetch fields for the selected form's project
    useEffect(() => {
        if (selectedForm?.projectsid) {
            FormsAPI.getFields(selectedForm.projectsid).then((response) => setAvailableFields(response.data.fields));
        }
    }, [selectedForm]);

    const handleAddForm = () => {
        setIsProjectDialogOpen(true); // Open project selection dialog
    };

    const handleProjectSelect = () => {
        const selectedProjectData = projects.find((project) => project.projectsid === selectedProject);

        setSelectedForm({
            form_title: '',
            projectsid: selectedProjectData.projectsid,
            project_name: selectedProjectData.project_name, // Set project name
            fields: [],
        });
        setIsEditing(true);
        setIsProjectDialogOpen(false); // Close the project selection dialog
    };


    const handleSaveForm = (form) => {
        const fieldIds = form.fields.map((field) => field.field_id);
        const formData = {
            formId: form.id,
            formTitle: form.form_title,
            projectsId: form.projectsid,
            fieldIds: fieldIds,
            formNotes: form.form_notes || '',
        };

        FormsAPI.saveForm(formData).then(() => {
            FormsAPI.getForms().then((response) => setForms(response.data.forms));
            setIsEditing(false);
        });
    };

    const handleSelectField = (field) => {
        setSelectedForm((prevForm) => ({
            ...prevForm,
            fields: [...prevForm.fields, field],
        }));
        setIsAddFieldDialogOpen(false);
    };

    const handleCreateNewField = (fieldName) => {
        if (!selectedForm || !selectedForm.projectsid) {
            alert('Please select a project before creating a new field.');
            return;
        }

        // Construct the new field data
        const newField = {
            projectsId: selectedForm.projectsid, // Include the projectsid
            fieldType: 'text',
            fieldName: fieldName,
        };

        // Call the API to create the new field
        FormsAPI.createField(newField).then(() => {
            // Refresh the available fields for the selected project
            FormsAPI.getFields(selectedForm.projectsid).then((response) => {
                setAvailableFields(response.data.fields);

                // Add the new field to the form's fields
                setSelectedForm((prevForm) => ({
                    ...prevForm,
                    fields: [...prevForm.fields, { ...newField, field_id: Date.now() }],
                }));
            });
        });
    };


    const handleUpdateForm = (updatedForm) => {
        setSelectedForm(updatedForm);
    };

    return (
        <Box display="flex">
            <FormSidebar forms={forms} onSelectForm={setSelectedForm} onAddForm={handleAddForm} />
            {selectedForm && (
                <FormDetails
                    form={selectedForm}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    onSave={() => handleSaveForm(selectedForm)}
                    onEdit={() => setIsEditing(true)}
                    onAddField={() => setIsAddFieldDialogOpen(true)}
                    onUpdateForm={handleUpdateForm}
                />
            )}
            <AddFieldDialog
                open={isAddFieldDialogOpen}
                onClose={() => setIsAddFieldDialogOpen(false)}
                existingFields={availableFields}
                onSelectField={handleSelectField}
                onCreateNewField={handleCreateNewField}
            />

            {/* Project Selection Dialog */}
            <Dialog open={isProjectDialogOpen} onClose={() => setIsProjectDialogOpen(false)}>
                <DialogTitle>Select a Project</DialogTitle>
                <DialogContent>
                    <Select
                        fullWidth
                        value={selectedProject}
                        onChange={(e) => setSelectedProject(e.target.value)}
                    >
                        {projects.map((project) => (
                            <MenuItem key={project.projectsid} value={project.projectsid}>
                                {project.project_name}
                            </MenuItem>
                        ))}
                    </Select>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsProjectDialogOpen(false)}>Cancel</Button>
                    <Button onClick={handleProjectSelect} color="primary">Select</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default FormManager;
