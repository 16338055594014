import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, TextField, Typography, Box, Grid, Paper, Dialog, DialogActions, DialogContent, DialogTitle, Alert } from '@mui/material';
import {MapContainer, Marker, TileLayer, useMapEvents} from 'react-leaflet';
import L from 'leaflet';


const crosshairIcon = new L.Icon({
    iconUrl: '/1530077_crosshair_direction_location_locator_navigation_icon.png',
    iconSize: [50, 50],
    iconAnchor: [25, 25],
});


function AdminProjectManager({ user, logout, handleLogin, loading }) {
    const [projectData, setProjectData] = useState({ projectid: '', project_name: '', project_url_slug: '', description: '', center_lat: null, center_lng: null, zoom_level: 13, logo_url: '' });
    const [projects, setProjects] = useState([]); // Initialize as an empty array
    const [newProject, setNewProject] = useState({ projectid: '', project_name: '', project_url_slug: '', description: '', logo_url: '' });
    const [editProject, setEditProject] = useState(null); // State for the project being edited
    const [openEditDialog, setOpenEditDialog] = useState(false); // Dialog state
    const [isEditingProjectId, setIsEditingProjectId] = useState(false); // State to track projectid editing
    const [showWarning, setShowWarning] = useState(false); // State for showing warning
    const [errorMessage, setErrorMessage] = useState(''); // State for error messages
    const [mapCenter, setMapCenter] = useState([43.115885, -77.512659]);
    const [zoom, setZoom] = useState(13);
    const [openMapModal, setOpenMapModal] = useState(false);
    const [selectedProjectsid, setSelectedProjectsid] = useState(null);

// When the Edit Map Center button is clicked, store the selected project in state
    const handleOpenMapCenterModal = (projectsid) => {
        setSelectedProjectsid(projectsid); // Store the selected project in state
        setOpenMapModal(true); // Open the map modal
    };

// Function to handle closing the modal
    const handleCloseMapModal = () => {
        setOpenMapModal(false);
    };
    const handleMapClick = (e) => {
        setMapCenter([e.latlng.lat, e.latlng.lng]);
        setProjectData({ ...projectData, center_lat: e.latlng.lat, center_lng: e.latlng.lng });
    };

    // Update zoom level when map zoom changes
    const handleZoom = (map) => {
        setZoom(map.getZoom());
        setProjectData({ ...projectData, zoom_level: map.getZoom() });
    };

    // Fetch Projects on component load
    useEffect(() => {
        fetchProjects();
    }, []);

    const fetchProjects = () => {
        axios.post('https://api.whoseland.work/adminProjectsManager.php', { action: 'get' })
            .then((response) => {
                if (response.data.success && Array.isArray(response.data.projects)) {
                    setProjects(response.data.projects);
                } else {
                    console.error('Expected an array but got:', response.data);
                    setProjects([]); // Reset to empty if invalid response
                }
            })
            .catch((error) => console.error('Error fetching projects:', error));
    };

    const handleAddProject = () => {
        // Validate form fields before submitting
        if (!newProject.projectid || !newProject.project_name || !newProject.project_url_slug || !newProject.description) {
            setErrorMessage('All fields are required');
            return;
        }

        // Ensure map center and zoom level are also present
        if (!mapCenter[0] || !mapCenter[1] || !zoom) {
            setErrorMessage('Map center and zoom level must be set');
            return;
        }

        axios.post('https://api.whoseland.work/adminProjectsManager.php', {
            action: 'add',
            projectid: newProject.projectid,
            project_name: newProject.project_name,
            project_url_slug: newProject.project_url_slug,
            description: newProject.description,
            logo_url: newProject.logo_url,
            center_lat: mapCenter[0],  // Pass the selected latitude
            center_lng: mapCenter[1],  // Pass the selected longitude
            zoom_level: zoom           // Pass the selected zoom level
        })
            .then(response => {
                if (response.data.success) {
                    // Fetch the updated projects after adding
                    fetchProjects();
                    setNewProject({ projectid: '', project_name: '', project_url_slug: '', description: '' });
                    setErrorMessage(''); // Clear error message
                } else {
                    setErrorMessage(response.data.error || 'Error adding project');
                }
            })
            .catch(error => console.error('Error adding project:', error));
    };



    const handleDeleteProject = (projectsid) => {
        axios.post('https://api.whoseland.work/adminProjectsManager.php', { action: 'delete', projectsid })
            .then(response => {
                if (response.data.success) {
                    setProjects(projects.filter(project => project.projectsid !== projectsid));
                } else {
                    setErrorMessage('Failed to delete the project');
                }
            })
            .catch(error => console.error('Error deleting project:', error));
    };

    const handleOpenEditDialog = (project) => {
        setEditProject(project);
        setIsEditingProjectId(false); // Reset projectid editing state
        setOpenEditDialog(true);
    };

    const handleEditProject = () => {
        axios.post('https://api.whoseland.work/adminProjectsManager.php', {
            action: 'update',
            projectsid: editProject.projectsid, // Use the auto-incremented projectsid
            projectid: editProject.projectid, // Manually specified projectid
            project_name: editProject.project_name,
            project_url_slug: editProject.project_url_slug,
            description: editProject.description,
            logo_url: editProject.logo_url,
        })
            .then(response => {
                if (response.data.success) {
                    setProjects(projects.map(project => project.projectsid === editProject.projectsid ? editProject : project));
                    setOpenEditDialog(false);
                } else {
                    setErrorMessage('Failed to update the project');
                }
            })
            .catch(error => console.error('Error updating project:', error));
    };

    // Modify the handleMapCenterEdit function to accept the selected project
    const handleMapCenterEdit = async () => {
        try {
            const response = await fetch('https://api.whoseland.work/adminProjectsManager.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    action: 'update_map_center', // Specify action type
                    projectsid: selectedProjectsid, // The ID of the selected project
                    center_lat: mapCenter[0], // Latitude
                    center_lng: mapCenter[1], // Longitude
                    zoom_level: zoom // Zoom level
                }),
            });

            const result = await response.json();

            if (result.success) {
                alert('Map center and zoom level updated successfully!');
                handleCloseMapModal(); // Close the modal after successful update
            } else {
                alert('Failed to update map center and zoom level.');
            }
        } catch (error) {
            console.error('Error updating map center and zoom level:', error);
            alert('An error occurred while updating the map center and zoom level.');
        }
    };


    const handleEditFieldChange = (field, value) => {
        setEditProject({ ...editProject, [field]: value });
    };

    const handleEditProjectId = () => {
        setIsEditingProjectId(true); // Enable projectid editing
        setShowWarning(true); // Show the warning message
    };


    return (
        <Box sx={{ width: '100%', height: '100%' }}>
            {/* Project Management Section */}
            <Grid sx={{ padding: 5 }}>
                <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', marginBottom: 4 }}>
                    Project Manager
                </Typography>

                {errorMessage && <Alert severity="error">{errorMessage}</Alert>}

                <Grid container spacing={3}>
                    {/* Existing Projects (left 2/3rd) */}
                    <Grid item xs={12}>
                        <Paper elevation={3} sx={{ padding: 2 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={4}>
                                    <Box>
                                        <Paper elevation={3} sx={{ padding: 3 }}>
                                            <Typography variant="h6" sx={{ marginBottom: 2 }}>Add New Project</Typography>
                                            <TextField
                                                label="Project ID"
                                                value={newProject.projectid}
                                                onChange={(e) => setNewProject({ ...newProject, projectid: e.target.value })}
                                                fullWidth
                                                margin="normal"
                                            />
                                            <TextField
                                                label="Project Name"
                                                value={newProject.project_name}
                                                onChange={(e) => setNewProject({ ...newProject, project_name: e.target.value })}
                                                fullWidth
                                                margin="normal"
                                            />
                                            <TextField
                                                label="Project URL Slug"
                                                value={newProject.project_url_slug}
                                                onChange={(e) => setNewProject({ ...newProject, project_url_slug: e.target.value })}
                                                fullWidth
                                                margin="normal"
                                            />
                                            <TextField
                                                label="Description"
                                                value={newProject.description}
                                                onChange={(e) => setNewProject({ ...newProject, description: e.target.value })}
                                                fullWidth
                                                margin="normal"
                                            />
                                            <TextField
                                                label="Project Logo URL"
                                                value={newProject.logo_url}
                                                onChange={(e) => setNewProject({ ...newProject, logo_url: e.target.value })}
                                                fullWidth
                                                margin="normal"
                                            />
                                            <Button variant="contained" color="primary" onClick={handleAddProject} fullWidth sx={{ marginTop: 2 }}>
                                                Add Project
                                            </Button>
                                        </Paper>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Paper elevation={3} sx={{ padding: 2 }}>
                                        <MapContainer center={mapCenter} zoom={zoom} style={{ height: '450px', cursor: 'crosshair' }} whenCreated={handleZoom}>
                                            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                                            <Marker position={mapCenter} icon={crosshairIcon} />
                                            <MapEvents onClick={handleMapClick} onZoom={handleZoom} />
                                        </MapContainer>
                                        <Typography variant="body2">Click on the map to set the center of the project.</Typography>
                                        <Typography variant="h6">Lat: {mapCenter[0]}, Lng: {mapCenter[1]}, ZoomLevel: {zoom}</Typography>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Paper elevation={3} sx={{ padding: 3 }}>
                            <Typography variant="h6" sx={{ marginBottom: 2 }}>Existing Projects</Typography>
                            {projects && Array.isArray(projects) && projects.length > 0 ? (
                                projects.map((project) => (
                                    <Grid container key={project.projectsid} spacing={2} sx={{ marginTop: 2 }}>
                                        <Grid item xs={6} sm={6} md={6} lg={4}>
                                            <Typography>{project.project_name || 'Unnamed Project'}</Typography> {/* Defensive check here */}
                                            <Typography>{project.projectid || 'N/A'}</Typography> {/* Defensive check here */}
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6} lg={4}>
                                            <Typography>{project.description || 'N/A'}</Typography> {/* Defensive check here */}
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={4}>
                                            <Button variant="contained" color="primary" onClick={() => handleOpenEditDialog(project)}>
                                                Edit
                                            </Button>
                                            <Button variant="contained" color="primary" onClick={() => handleOpenMapCenterModal(project.projectsid)} sx={{ marginLeft: 2 }}>
                                                Edit Map Center
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => {
                                                    if (window.confirm("Are you sure you want to delete this project?")) {
                                                        handleDeleteProject(project.projectsid);
                                                    }
                                                }}
                                                sx={{ marginLeft: 2 }}
                                            >
                                                Delete
                                            </Button>
                                        </Grid>
                                    </Grid>
                                ))
                            ) : (
                                <Typography>No projects found or available.</Typography>
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>

            {/* Edit Project Dialog */}
            <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
                <DialogTitle>Edit Project</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Project ID"
                        value={editProject?.projectid || ''}
                        onChange={(e) => handleEditFieldChange('projectid', e.target.value)}
                        fullWidth
                        margin="normal"
                        disabled={!isEditingProjectId} // Disable editing unless enabled
                    />
                    {showWarning && <Alert severity="warning">Warning: Editing the Project ID can cause significant issues. Proceed with caution.</Alert>}
                    {!isEditingProjectId && (
                        <Button onClick={handleEditProjectId} variant="contained" color="secondary" sx={{ marginTop: 2 }}>
                            Edit Project ID
                        </Button>
                    )}
                    <TextField
                        label="Project Name"
                        value={editProject?.project_name || ''}
                        onChange={(e) => handleEditFieldChange('project_name', e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Project URL Slug"
                        value={editProject?.project_url_slug || ''}
                        onChange={(e) => handleEditFieldChange('project_url_slug', e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Description"
                        value={editProject?.description || ''}
                        onChange={(e) => handleEditFieldChange('description', e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Project Logo URL"
                        value={editProject?.logo_url || ''}
                        onChange={(e) => handleEditFieldChange('logo_url', e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenEditDialog(false)}>Cancel</Button>
                    <Button onClick={handleEditProject} color="primary" variant="contained">Save</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openMapModal} onClose={handleCloseMapModal} maxWidth="md" fullWidth>
                <DialogTitle>Edit Map Center</DialogTitle>
                <DialogContent>
                    <Paper elevation={3} sx={{ padding: 2 }}>
                        <MapContainer center={mapCenter} zoom={zoom} style={{ height: '400px', cursor: 'crosshair' }} whenCreated={handleZoom}>
                            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                            <Marker position={mapCenter} icon={crosshairIcon} />
                            <MapEvents onClick={handleMapClick} onZoom={handleZoom} />
                        </MapContainer>
                        <Typography variant="body2">Click on the map to set the center of the project.</Typography>
                        <Typography variant="h6">Lat: {mapCenter[0]}, Lng: {mapCenter[1]}, ZoomLevel: {zoom}</Typography>
                    </Paper>
                </DialogContent>
                <Button onClick={handleMapCenterEdit} variant="contained" color="primary">Submit New Map Center</Button>
                <Button onClick={handleCloseMapModal}>Close</Button>
            </Dialog>
        </Box>
    );
}
function MapEvents({ onClick, onZoom }) {
    useMapEvents({
        click: onClick,
        zoomend: (e) => onZoom(e.target), // Update zoom when zoom changes
    });
    return null;
}
export default AdminProjectManager;
