import React, { useState, useEffect } from 'react';
import './decade_map.css';
import {
    Button,
    TextField,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    Typography,
    Box,
    IconButton, FormControlLabel, Stack
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useAuth } from './authContext';
import { useProject } from './ProjectContext';
import FormsAPI from './FormsAPI';
import { Close } from '@mui/icons-material';
import {Chip, FormLabel, Radio, RadioGroup} from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';
import {Form} from "react-hook-form";

function ContributionComp(props) {
    const { user } = useAuth();
    const { project } = useProject();
    const [contFirstName, setContFirstName] = useState('');
    const [contLastName, setContLastName] = useState('');
    const [contEmail, setContEmail] = useState('');
    const [forms, setForms] = useState([]);
    const [selectedForm, setSelectedForm] = useState(null);
    const [formData, setFormData] = useState({
        year: props.year,
        address: props.selectedAddress,
        loc_id: props.selectedAddressLatLng ? `${props.selectedAddressLatLng.lat},${props.selectedAddressLatLng.lng}` : "",
        uid: user ? user.uid : `${contFirstName}_${contLastName}_${contEmail}`,
        form_id: null,
        projectsid: project ? project.projectsid : null,
        field_data: []
    });
    const [error, setError] = useState({});
    const [files, setFiles] = useState({});
    const [selectionType, setSelectionType] = useState('marker');
    const [progress, setProgress] = useState(0);

    // Default to marker


    // Fetch forms for the selected project
    useEffect(() => {
        if (project) {
            FormsAPI.getFormsProject(project.projectsid).then((response) => {
                const fetchedForms = response.data.forms;
                console.log(fetchedForms);
                setForms(fetchedForms);
            });
        }
    }, [project]);
    useEffect(() => {
        if (forms.length > 0 && !selectedForm) {
            // Check if there is only one form, and select it by default if so
            if (forms.length === 1) {
                handleFormSelect(forms[0].id);
            } else {
                // Attempt to select the form with the given ID
                handleFormSelect(270); // Replace 270 with the form ID you want to select by default
            }
        }
    }, [forms, selectedForm]);


    // Update formData when selectedAddress changes
    useEffect(() => {
        setFormData((prevData) => ({
            ...prevData,
            address: props.selectedAddress
        }));
    }, [props.selectedAddress]);

    // Update formData when selectedAddressLatLng changes
    useEffect(() => {
        if (props.selectedAddressLatLng) {
            setFormData((prevData) => ({
                ...prevData,
                loc_id: `${props.selectedAddressLatLng.lat},${props.selectedAddressLatLng.lng}`
            }));
        }
    }, [props.selectedAddressLatLng]);

    const handleFormSelect = (formId) => {
        const form = forms.find((f) => f.id === formId);

        if (!form) {
            console.error("Form not found for the given ID:", formId);
            return;
        }

        setSelectedForm(form);

        // Check if the form has an 'address' field
        const addressField = form.fields.find((field) => field.field_type === 'address');

        setFormData((prevData) => ({
            ...prevData,
            form_id: form.id,
            field_data: form.fields.map((field) => ({
                field_id: field.field_id,
                field_value: field.field_type === 'address' ? props.selectedAddress || '' : '',
            })),
        }));
    };



    const handleChange = async (event) => {
        const { name, value } = event.target;

        if (name === 'address') {
            props.setSelectedAddress(value);
            setFormData((prevData) => ({ ...prevData, address: value }));

            // If the address field is updated, query the Google API to get lat, lng
            if (value) {
                try {
                    const encodedAddress = encodeURIComponent(value);
                    const apiKey = 'AIzaSyCllzLZfdBPfaSa65Y4vwTIrONb3IuZ4ac'; // Replace with your actual Google API key
                    const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=${apiKey}`);
                    const data = await response.json();

                    if (data.status === 'OK') {
                        const { lat, lng } = data.results[0].geometry.location;
                        setFormData((prevData) => ({
                            ...prevData,
                            loc_id: `${lat},${lng}`
                        }));
                    } else {
                        console.error('Error fetching geolocation:', data.status);
                    }
                } catch (error) {
                    console.error('Error during geolocation fetch:', error);
                }
            }
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value
            }));
        }

        setError((prev) => ({
            ...prev,
            [name]: value ? "" : `${name} is required`
        }));
    };

    const handleFieldChange = (fieldId, value) => {
        setFormData((prevData) => {
            const updatedFieldData = prevData.field_data.map((field) =>
                field.field_id === fieldId
                    ? { ...field, field_value: value }
                    : field
            );

            // If the field is an address field, also update the props.selectedAddress
            const addressField = selectedForm.fields.find(
                (field) => field.field_id === fieldId && field.field_type === 'address'
            );
            if (addressField) {
                props.setSelectedAddress(value);
            }

            return { ...prevData, field_data: updatedFieldData };
        });
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        // Validate required fields
        let newErrors = {};
        if (!formData.year) newErrors.year = "Year is required";
        if (selectionType === "marker" && !formData.address) {
            newErrors.address = "Address is required";
        }
        if (Object.keys(newErrors).length > 0) {
            setError(newErrors);
            return;
        }

        try {
            const submissionFormData = new FormData();
            submissionFormData.append("uid", formData.uid);
            submissionFormData.append("year", formData.year);
            submissionFormData.append("form_id", formData.form_id);
            submissionFormData.append("projectsid", formData.projectsid);
            submissionFormData.append("shape_type", selectionType);
            submissionFormData.append("loc_id", formData.loc_id);

            // Update the address field in field_data
            const updatedFieldData = formData.field_data.map((field) => {
                const formField = selectedForm.fields.find(
                    (f) => f.field_id === field.field_id
                );
                if (formField && formField.field_type === 'address') {
                    return { ...field, field_value: props.selectedAddress || '' };
                }
                return field;
            });

            submissionFormData.append("field_data", JSON.stringify(updatedFieldData));

            // Add files to the submission
            for (const fieldId in files) {
                files[fieldId].forEach((file) => {
                    submissionFormData.append(`file_${fieldId}[]`, file);
                });
            }

            // Use XMLHttpRequest for upload progress tracking
            const xhr = new XMLHttpRequest();
            xhr.open("POST", "https://api.whoseland.work/submitDynDataNU.php");

            // Track upload progress
            xhr.upload.onprogress = (event) => {
                if (event.lengthComputable) {
                    const percentCompleted = Math.round((event.loaded / event.total) * 100);
                    setProgress(percentCompleted);
                }
            };

            xhr.onload = () => {
                const result = JSON.parse(xhr.responseText);
                if (result.success) {
                    alert("Thank you for your submission!");
                    props.setShowContribution(false);
                    props.setAddStoryMode(false);
                    props.clearSelectedAddress();
                    props.refreshData();
                } else {
                    alert(`Error: ${result.message}`);
                }
                setProgress(0); // Reset progress
            };

            xhr.onerror = () => {
                alert("An error occurred during submission.");
                setProgress(0); // Reset progress
            };

            xhr.send(submissionFormData);
        } catch (error) {
            console.error("Error during submission:", error);
            alert("Submission failed.");
            setProgress(0); // Reset progress
        }
    };




    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        whiteSpace: 'nowrap',
        width: 1,
    });


    const handleFileChange = (event, fieldId) => {
        const selectedFiles = Array.from(event.target.files);

        setFiles((prev) => ({
            ...prev,
            [fieldId]: [...(prev[fieldId] || []), ...selectedFiles], // Initialize array if undefined
        }));
    };


    const handleRemoveFile = (fieldId, fileName) => {
        setFiles((prev) => {
            // Safeguard: Check if fieldId exists in the files object
            if (!prev[fieldId]) {
                console.warn(`No files found for fieldId: ${fieldId}`);
                return prev;
            }

            return {
                ...prev,
                [fieldId]: prev[fieldId].filter((file) => file.name !== fileName),
            };
        });
    };





    const handleYearChange = (event) => {
        props.setYear(event.target.value); // Update the shared year state
    };
    const handleSelectionChange = (event) => {
        setSelectionType(event.target.value);
        if (event.target.value === 'polygon') {
            props.activatePolygonDrawing(); // Activate polygon drawing
        }
    };
    useEffect(() => {
        setFormData((prevData) => ({
            ...prevData,
            year: props.year,
        }));
    }, [props.year]);




    return (
        <div style={{ position: 'absolute', right: 0, top: 0, width: '100%', height: '100%', overflowY: 'scroll', background: '#f5f5f5', zIndex: 1000, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start' }}>
            <Box sx={{ position: 'absolute', top: 0, right: 0, zIndex: 10000 }}>
                <IconButton
                    aria-label="close"
                    onClick={() => {
                        props.setShowContribution(false);
                        props.clearSelectedAddress();
                        props.setSelectedMarker(null);
                        props.setAddStoryMode(false);
                    }}
                >
                    <Close />
                </IconButton>
            </Box>
            {/*<FormControl component="fieldset" sx={{marginTop: 4, marginBottom: 4}}>
                <FormLabel component="legend">Select Place Type</FormLabel>
                <RadioGroup
                    row
                    aria-label="type"
                    name="type"
                    value={selectionType}
                    onChange={handleSelectionChange}
                >
                    <FormControlLabel value="marker" control={<Radio/>} label="Marker"/>
                    <FormControlLabel value="polygon" control={<Radio/>} label="Polygon"/>
                </RadioGroup>
            </FormControl>*/}

            <form onSubmit={handleSubmit} style={{width: '80%', display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', alignContent: 'center', alignItems: 'stretch', justifyContent: 'flex-start' }}>
                {forms.length > 1 && (
                    <FormControl margin="normal" sx={{ marginTop: 4, marginBottom: 4 }}>
                        <InputLabel>Select a Form</InputLabel>
                        <Select
                            value={selectedForm ? selectedForm.id : ""}
                            onChange={(e) => handleFormSelect(e.target.value)}
                            label="Select a Form"
                        >
                            {forms.map((form) => (
                                <MenuItem key={form.id} value={form.id}>{form.form_title}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
                    {!props.addStoryMode &&
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', marginTop: 10 }}>
                            <Button variant="outlined" onClick={() => props.setSelectMode(true)}>Select on Map</Button>
                            <Typography sx={{ marginTop: 2, marginBottom: 2 }}><strong>OR</strong></Typography>
                            <Typography sx={{ marginTop: 0, marginBottom: 2 }}>Type an Address</Typography>
                            <TextField
                                label="Address"
                                type="text"
                                name="address"
                                value={formData.address}
                                onChange={handleChange}
                                error={Boolean(error.address)}
                                helperText={error.address}
                            />
                        </Box>
                    }

                <FormControl sx={{ marginTop: '20px', marginBottom: '10px' }}>
                    <InputLabel>Year</InputLabel>
                    <Select
                        label="YEAR"
                        name="year"
                        value={props.year}
                        onChange={handleYearChange}
                        error={Boolean(error.year)}
                    >
                        <MenuItem value="">Select</MenuItem>
                        <MenuItem value="1760">Pre-1770</MenuItem>
                        {[...Array(26).keys()].map(i => (
                            <MenuItem key={i} value={1770 + (i * 10)}>
                                {1770 + (i * 10)}s
                            </MenuItem>
                        ))}
                        <MenuItem value="2030">Vision</MenuItem>
                    </Select>
                </FormControl>


                {selectedForm && selectedForm.fields
                        .filter(field => field.field_type !== 'address')
                        .map((field) => {
                            if (field.field_type === 'file') {
                                return (
                                    <Box key={field.field_id} sx={{ marginTop: '10px', marginBottom: '10px' }}>
                                        <Typography variant="h6">{field.field_name.charAt(0).toUpperCase() + field.field_name.slice(1)}</Typography>
                                        <label htmlFor={`file-upload-${field.field_id}`}>
                                            <VisuallyHiddenInput
                                                accept="image/*,audio/mp3,audio/*"
                                                id={`file-upload-${field.field_id}`}
                                                multiple
                                                type="file"
                                                onChange={(e) => handleFileChange(e, field.field_id)}
                                            />
                                            <Button
                                                component="span"
                                                variant="contained"
                                                startIcon={<CloudUploadIcon />}
                                            >
                                                Upload File
                                            </Button>
                                        </label>
                                        <Stack direction="column" spacing={1} marginTop={2}>
                                            {files[field.field_id]?.map((file, index) => (
                                                <Chip
                                                    key={index}
                                                    label={file.name || "Unnamed File"}
                                                    onClick={() => console.log(`Clicked on file: ${file.name}`)}
                                                    onDelete={() => handleRemoveFile(field.field_id, file.name)}
                                                    color="primary"
                                                    variant="outlined"
                                                    deleteIcon={<DeleteOutlineIcon/>}
                                                    sx={{
                                                        width: 'fit-content', // Ensure Chip width adjusts to the content
                                                        maxWidth: '100%', // Prevent overflow
                                                    }}
                                                />
                                            ))}
                                        </Stack>
                                    </Box>
                                );
                            } else {
                                // Other field types
                                return (
                                    <TextField
                                        key={field.field_id}
                                        multiline
                                        rows={3}
                                        sx={{ marginTop: '10px', marginBottom: '10px' }}
                                        label={field.field_name}
                                        type={field.field_type === 'number' ? 'number' : 'text'}
                                        value={formData.field_data.find(f => f.field_id === field.field_id)?.field_value || ''}
                                        onChange={(e) => handleFieldChange(field.field_id, e.target.value)}
                                        error={field.field_type === "header" && Boolean(error.header)}
                                        helperText={field.field_type === "header" && error.header ? `${field.field_name} is required` : ""}
                                    />
                                );
                            }
                        })}
                    {!user && (
                        <>
                            <br/>
                            <br/>
                            <br/>
                            <Typography><strong>Please fill in your information below.</strong></Typography>
                            <br/>
                            <TextField
                                label="First Name"
                                type="text"
                                name="contFirstName"
                                value={contFirstName}
                                onChange={(e) => setContFirstName(e.target.value)}
                                className='form-input'
                            />
                            <br/>
                            <br/>
                            <TextField
                                label="Last Name"
                                type="text"
                                name="contLastName"
                                value={contLastName}
                                onChange={(e) => setContLastName(e.target.value)}
                                className='form-input'
                            />
                            <br/>
                            <br/>
                            <TextField
                                label="Email"
                                type="email"
                                name="contEmail"
                                value={contEmail}
                                onChange={(e) => setContEmail(e.target.value)}
                                className='form-input'
                                error={Boolean(error.email)}
                                helperText={error.email}
                            />
                        </>
                    )}

                    {/*<label htmlFor="raised-button-file">
                        <VisuallyHiddenInput
                            accept="image/*,audio/*"
                            id="raised-button-file"
                            multiple
                            type="file"
                            onChange={(e) => setFiles([...files, ...e.target.files])}
                        />
                        <Button variant="contained" component="span" startIcon={<CloudUploadIcon/>}>
                            Upload File
                        </Button>
                    </label>
                        <Box mt={2}>
                    {files.map((file, index) => (
                        <Typography key={index}>
                            {file.name}
                            <IconButton onClick={() => setFiles(files.filter((_, i) => i !== index))}>
                                <DeleteOutlineIcon/>
                            </IconButton>
                        </Typography>
                    ))}
                </Box>
                */}

                {progress > 0 && (
                    <div style={{ margin: "20px 0" }}>
                        <LinearProgress variant="determinate" value={progress} />
                        <Typography variant="body2" color="textSecondary">
                            Uploading: {progress}%
                        </Typography>
                    </div>
                )}
                <Button sx={{ marginTop: '10px', marginBottom: '25px' }} variant="contained" type="submit">Submit</Button>
            </form>
        </div>
    );
}

export default ContributionComp;
