import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    ListItemButton, Typography
} from '@mui/material';

const AddFieldDialog = ({ open, onClose, existingFields, onSelectField, onCreateNewField }) => {
    const [fieldName, setFieldName] = useState('');
    const [fieldType, setFieldType] = useState('text'); // Default field type

    const handleCreateField = () => {
        if (fieldName.trim() !== '') {
            // Pass the new field details, including the selected field type
            onCreateNewField(fieldName, fieldType);
            setFieldName('');
            setFieldType('text'); // Reset to default field type
            onClose();
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Add Field</DialogTitle>
            <DialogContent>
                {/* Section for creating a new field */}
                <TextField
                    autoFocus
                    margin="dense"
                    label="Field Name"
                    fullWidth
                    value={fieldName}
                    onChange={(e) => setFieldName(e.target.value)}
                />
                <FormControl fullWidth margin="dense">
                    <InputLabel>Field Type</InputLabel>
                    <Select
                        value={fieldType}
                        onChange={(e) => setFieldType(e.target.value)}
                    >
                        <MenuItem value="header">Header</MenuItem>
                        <MenuItem value="text">Text</MenuItem>
                        <MenuItem value="number">Number</MenuItem>
                        <MenuItem value="date">Date</MenuItem>
                        <MenuItem value="boolean">Boolean</MenuItem>
                        <MenuItem value="address">Address</MenuItem>
                        <MenuItem value="file">file</MenuItem>
                        {/* Add more field types as needed */}
                    </Select>
                </FormControl>

                {/* Divider between new and existing fields */}
                <hr style={{ margin: '16px 0' }} />

                {/* Section for selecting existing fields */}
                <Typography variant="h6" gutterBottom>Existing Fields</Typography>
                <List>
                    {existingFields.map((field) => (
                        <ListItemButton key={field.field_id} onClick={() => onSelectField(field)}>
                            <ListItemText primary={`${field.field_name} (${field.field_type})`} />
                        </ListItemButton>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleCreateField} color="primary" variant="contained">
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddFieldDialog;
