import React, { useEffect, useState } from 'react';
import {
    Grid,
    Box,
    Typography,
    IconButton,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Stack,
    Chip,
    Link,
    TextField
} from '@mui/material';
import {
    Close,
    NavigateBefore,
    NavigateNext,
    ArrowBackIos,
    ArrowForwardIos,
    AddCircle as AddCircleIcon,
    Delete,
    Save,
    Edit as EditIcon
} from '@mui/icons-material';
import Comments from './Comments';
import { useAuth } from './authContext';
import { useProject } from './ProjectContext';
import axios from 'axios';
import './SidePanelComponent.css';

const SidePanelComponent = ({
                                isSidePanelOpen,
                                setIsSidePanelOpen,
                                year,
                                setYear,
                                setSelectedMarker,
                                setSelectedAddressLatLng,
                                setSelectedAddress,
                                setShowContribution,
                                markerIds,
                                refreshData,
                                setIsEditing,
                                isEditing,
                                polygonData,
                                addStoryMode,
                                setAddStoryMode,
                                selectedAddressLatLng
                            }) => {
    const { user, globalRoles, projectRoles } = useAuth();
    const { project } = useProject();
    const [data, setData] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [carouselFiles, setCarouselFiles] = useState([]);
    const [nonImageFiles, setNonImageFiles] = useState([]);
    const [currentCarouselIndex, setCurrentCarouselIndex] = useState(0);
    const [imageDialogOpen, setImageDialogOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [editFormData, setEditFormData] = useState({});
    const [fileEditDialogOpen, setFileEditDialogOpen] = useState(false);
    const [newFiles, setNewFiles] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                if (markerIds && markerIds.length > 0) {
                    const response = await fetch(
                        `https://api.whoseland.work/pullsidepanel.php?markerIds=${markerIds.join(
                            ','
                        )}&projectsid=${project.projectsid}&year=${year}`
                    );
                    const rawData = await response.json();
                    setData(rawData);
                }

                if (polygonData) {
                    const response = await fetch(
                        `https://api.whoseland.work/getPolygonData.php?id=${polygonData.id}&projectsid=${project.projectsid}`
                    );
                    const polygonSubmissionData = await response.json();
                    setData((prevData) => [polygonSubmissionData, ...prevData]);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (isSidePanelOpen) {
            fetchData();
        }
    }, [isSidePanelOpen, markerIds, polygonData, project.projectsid, year]);

    useEffect(() => {
        if (data.length > 0) {
            setSelectedItems(data);
        } else {
            setSelectedItems([{ year: year.toString(), fields: [] }]);
        }
        setCurrentPage(0);
    }, [data]);

    const fetchFiles = async () => {
        if (!selectedItems[currentPage]?.id) return;

        const submissionId = selectedItems[currentPage].id;

        try {
            const response = await axios.get(
                `https://api.whoseland.work/getFiles.php?submission_id=${submissionId}`
            );
            const fetchedFiles = response.data || [];

            // Ensure all entries have a valid filepath before processing
            const validFiles = fetchedFiles.filter((file) => file.filepath);

            const images = validFiles.filter((file) =>
                file.filepath.toLowerCase().match(/\.(jpg|jpeg|png|gif|bmp)$/i)
            );
            const others = validFiles.filter(
                (file) => !file.filepath.toLowerCase().match(/\.(jpg|jpeg|png|gif|bmp)$/i)
            );

            setCarouselFiles(images.map((file) => ({
                id: file.id,
                filepath: file.filepath,
            })));

            setNonImageFiles(
                others.map((file) => ({
                    id: file.id, // Ensure the ID is included
                    name: file.filename || file.filepath.split('/').pop(),
                    link: file.filepath,
                }))
            );
        } catch (error) {
            console.error('Error fetching files:', error);
            setCarouselFiles([]);
            setNonImageFiles([]); // Reset non-image files in case of error
        }
    };

    useEffect(() => {
        if (selectedItems.length > 0) {
            fetchFiles();
        }
    }, [selectedItems, currentPage]);

    const handleNextCarousel = () => {
        setCurrentCarouselIndex((prevIndex) => prevIndex + 1);
    };

    const handlePreviousCarousel = () => {
        setCurrentCarouselIndex((prevIndex) => prevIndex - 1);
    };

    const handleImageClick = (image) => {
        setSelectedImage(image);
        setImageDialogOpen(true);
    };

    const handleCloseImageDialog = () => {
        setImageDialogOpen(false);
        setSelectedImage(null);
    };
    const canEditOrDelete = () => {
        const projectAdminRole = project.projectid + '_Admin';
        return (
            user &&
            (globalRoles.isAdmin || globalRoles.isDev || (projectRoles && projectRoles.includes(projectAdminRole)))
        );
    };


    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setEditFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleEdit = () => {
        setIsEditing(true);
        // Include the year in the edit form data
        setEditFormData({
            year: selectedItems[currentPage]?.year || year, // Default to the current year if not set
            ...selectedItems[currentPage]?.fields.reduce((acc, field) => {
                acc[field.field_name] = field.field_value || '';
                return acc;
            }, {})
        });
    };

    const handleSaveChanges = async () => {
        try {
            const currentItem = selectedItems[currentPage];
            if (!currentItem) return;

            const payload = {
                action: 'edit',
                markerId: currentItem.id,
                projectsid: Number(project.projectsid),
                year: Number(editFormData.year), // Use the updated year
                fields: Object.keys(editFormData).reduce((acc, key) => {
                    if (key !== 'year') {
                        acc[key] = editFormData[key];
                    }
                    return acc;
                }, {})
            };

            const response = await fetch(`https://api.whoseland.work/EDRDynamic.php`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            });

            const result = await response.json();

            if (result.success) {
                setData((prevData) => {
                    const updatedData = [...prevData];
                    updatedData[currentPage].fields = updatedData[currentPage].fields.map((field) => ({
                        ...field,
                        field_value: editFormData[field.field_name] || null,
                    }));
                    updatedData[currentPage].year = editFormData.year; // Update the year
                    return updatedData;
                });
                setIsEditing(false);
            } else {
                alert('Failed to save changes.');
            }
        } catch (error) {
            console.error('Error saving changes:', error);
            alert('An error occurred while saving the changes.');
        }
    };


    const handleDelete = async () => {
        try {
            const currentItem = selectedItems[currentPage];
            if (!currentItem) return;

            const payload = {
                action: 'delete',
                markerId: currentItem.id,
                projectsid: Number(project.projectsid),
                year: Number(currentItem.year),
            };

            const response = await fetch(`https://api.whoseland.work/EDRDynamic.php`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            });

            const result = await response.json();

            if (result.success) {
                setData((prevData) => {
                    const updatedData = [...prevData];
                    updatedData.splice(currentPage, 1);
                    return updatedData;
                });
                refreshData();
                setIsEditing(false);

                if (currentPage > 0) {
                    setCurrentPage(currentPage - 1);
                }

                if (data.length === 1) {
                    setIsSidePanelOpen(false);
                }
            } else {
                alert('Failed to delete entry.');
            }
        } catch (error) {
            console.error('Error deleting entry:', error);
            alert('An error occurred while deleting the entry.');
        }
    };

    useEffect(() => {
        const fetchAddressIfMissing = async () => {
            // Check if `selectedAddressLatLng` is set and there is no valid address field in `data`
            if (
                selectedAddressLatLng &&
                (data.length === 0 ||
                    !data[0].fields.some(field => field.field_type === "address" && field.field_value)
                )
            ) {
                const { lat, lng } = selectedAddressLatLng;

                try {
                    const apiKey = "AIzaSyCllzLZfdBPfaSa65Y4vwTIrONb3IuZ4ac";
                    const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`);
                    const geocodeData = await response.json();

                    if (geocodeData.results && geocodeData.results.length > 0) {
                        const address = geocodeData.results[0].formatted_address;
                        // Add or update the address field in `data`
                        setData(prevData => {
                            const updatedData = [...prevData];

                            if (updatedData.length === 0) {
                                // If data is empty, initialize it with a new item containing the address
                                updatedData.push({
                                    fields: [
                                        {
                                            field_name: "Address",
                                            field_value: address,
                                            field_type: "address"
                                        }
                                    ]
                                });
                            } else {
                                // Check if the address field already exists
                                const addressField = updatedData[0].fields.find(field => field.field_type === "address");
                                if (addressField) {
                                    // Update the existing address field
                                    addressField.field_value = address;
                                } else {
                                    // Add the address field if it doesn't exist
                                    updatedData[0].fields.push({
                                        field_name: "Address",
                                        field_value: address,
                                        field_type: "address"
                                    });
                                }
                            }
                            return updatedData;
                        });
                        setSelectedAddress(address);
                    }
                } catch (error) {
                    console.error('Error fetching address:', error);
                }
            }
        };

        if (isSidePanelOpen) {
            fetchAddressIfMissing();
        }
    }, [selectedAddressLatLng, isSidePanelOpen, data]);

    const currentItem = selectedItems[currentPage] || { fields: [] };

    const getFieldIdForFiles = () => {
        const fileField = currentItem.fields.find((field) => field.field_type === "file");

        if (!fileField) {
            console.warn("No file field found for the current submission.");
            return null;
        }

        return fileField.field_id;
    };

    const handleFileAdd = (event) => {
        const selectedFiles = Array.from(event.target.files);
        setNewFiles((prev) => [...prev, ...selectedFiles]);
    };
    const handleFileDelete = async (file) => {
        try {
            await axios.delete(`https://api.whoseland.work/deleteFile.php`, {
                data: { file_id: file.id },
            });
            setNonImageFiles((prev) => prev.filter((f) => f.id !== file.id));
            setCarouselFiles((prev) => prev.filter((f) => f.id !== file.id));
            fetchFiles(); // Refresh files
        } catch (error) {
            console.error('Error deleting file:', error);
        }
    };


    const uploadFiles = async () => {
        if (!currentItem.id || !user || newFiles.length === 0) {
            console.warn("Missing necessary parameters for uploading files.");
            return;
        }

        const fieldId = getFieldIdForFiles();

        if (!fieldId) {
            console.warn("Could not retrieve field ID for file upload.");
            return;
        }

        const formData = new FormData();
        formData.append("submission_id", currentItem.id); // Submission ID
        formData.append("field_id", fieldId); // Field ID
        formData.append("firebase_uid", user.uid); // Firebase UID

        newFiles.forEach((file) => {
            formData.append("files[]", file);
        });

        try {
            const response = await axios.post("https://api.whoseland.work/uploadFiles.php", formData, {
                headers: { "Content-Type": "multipart/form-data" },
            });

            console.log("Upload response:", response.data);
            if (response.data.success) {
                fetchFiles(); // Refresh files after successful upload
                setNewFiles([]); // Clear new files list
                setFileEditDialogOpen(false); // Close dialog
            } else {
                alert(`Error uploading files: ${response.data.message}`);
            }
        } catch (error) {
            console.error("Error uploading files:", error);
            alert("An error occurred while uploading files.");
        }
    };



    const handleNext = () => {
        if (currentPage < selectedItems.length - 1) {
            setCurrentPage(currentPage + 1);
            setCurrentCarouselIndex(0);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
            setCurrentCarouselIndex(0);
        }
    };

    if (!isSidePanelOpen) return null;

    return (
        <Grid item xs={12} sm={12} md={12} className="side-panel-container">
            <Box className="side-panel">
                {/* Header */}
                <Box className="side-panel-header">
                    <Box sx={{ position: 'absolute', top: 10, right: 10, zIndex: 1000 }}>
                        <IconButton
                            aria-label="close"
                            onClick={() => {
                                setIsSidePanelOpen(false);
                                setShowContribution(false);
                                setSelectedAddressLatLng(null);
                                setSelectedMarker(null);
                            }}
                        >
                            <Close />
                        </IconButton>
                    </Box>
                    <Box className="date-box">
                        {year !== 1760 && (
                            <IconButton onClick={() => setYear(year - 10)}>
                                <NavigateBefore />
                            </IconButton>
                        )}
                        <Box className="date-font">
                            {year === 2030 ? (
                                <>Vision <br /> (Beyond 2020)</>
                            ) : year === 1760 ? (
                                <>Pre-1770</>
                            ) : (
                                year
                            )}
                        </Box>
                        {year !== 2030 && (
                            <IconButton onClick={() => setYear(year + 10)}>
                                <NavigateNext />
                            </IconButton>
                        )}
                    </Box>

                    <Typography className="page-header">
                        {currentItem.fields.find((field) => field.field_type === 'header')?.field_value ||
                            'No Data Available for Selected Year.'}
                    </Typography>
                </Box>

                {/* Scrollable Content */}
                <Box className="side-panel-content">
                    {currentItem.fields.map((field, index) => (
                        <div key={index}>
                            <Typography>
                                <strong>{field.field_name.charAt(0).toUpperCase() + field.field_name.slice(1)}:</strong>
                            </Typography>
                            {field.field_type === "file" ? (
                                <Box sx={{marginTop: 1, marginBottom: 2}}>
                                    {(() => {
                                        let fileLinks = [];
                                        try {
                                            const parsed = JSON.parse(field.field_value);
                                            if (Array.isArray(parsed)) {
                                                fileLinks = parsed.map((file) =>
                                                    typeof file === "string"
                                                        ? {link: file.trim(), name: file.split("/").pop()} // Fallback for string links
                                                        : {
                                                            link: file.webViewLink,
                                                            name: file.name || file.webViewLink.split("/").pop(),
                                                        } // JSON with or without name
                                                );
                                            }
                                        } catch {
                                            // If parsing fails, treat it as a comma-separated string
                                            fileLinks = field.field_value
                                                .split(",")
                                                .filter((link) => link.trim()) // Ensure no empty strings are included
                                                .map((link) => ({
                                                    link: link.trim(),
                                                    name: link.split("/").pop(), // Fallback to URL end
                                                }));
                                        }

                                        return (
                                            fileLinks.length > 0 && ( // Render only if there are valid file links
                                                <Stack direction="column" spacing={1}>
                                                    {fileLinks.map((file, idx) => (
                                                        <Chip
                                                            key={idx}
                                                            label={file.name || file.link} // Fallback to file.link if file.name doesn't exist
                                                            clickable
                                                            component={Link}
                                                            href={file.link}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            color="primary"
                                                            variant="outlined"
                                                        />
                                                    ))}
                                                </Stack>
                                            )
                                        );
                                    })()}
                                </Box>
                            ) : (
                                <Typography>{field.field_value}</Typography> // Fallback for other field types
                            )}
                        </div>


                    ))}
                    {carouselFiles.length > 0 && (
                        <Box className="carousel-container">
                            <Box className="carousel">
                                <IconButton
                                    onClick={handlePreviousCarousel}
                                    className="carousel-arrow"
                                    disabled={currentCarouselIndex === 0}
                                >
                                    <ArrowBackIos />
                                </IconButton>
                                <img
                                    src={carouselFiles[currentCarouselIndex]?.filepath}
                                    alt={`Image ${currentCarouselIndex + 1}`}
                                    className="carousel-image"
                                    onClick={() => handleImageClick(carouselFiles[currentCarouselIndex])}
                                />
                                <IconButton
                                    onClick={handleNextCarousel}
                                    className="carousel-arrow"
                                    disabled={currentCarouselIndex === carouselFiles.length - 1}
                                >
                                    <ArrowForwardIos />
                                </IconButton>
                            </Box>
                        </Box>
                    )}
                    <br/>
                    {nonImageFiles.map((file, idx) => (
                        <Chip
                            key={idx}
                            label={file.name}
                            clickable
                            component={Link}
                            href={file.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            color="primary"
                            variant="outlined"
                            sx={{ marginTop: 1 }}
                        />
                    ))}
                    <Box>
                        {currentItem.id &&
                            <Comments submission_id={currentItem.id}/>
                        }
                    </Box>
                </Box>

                {/* Footer */}
                <Box className="side-panel-footer">
                    <Box className='side-panel-footer-edit-add'>
                        {canEditOrDelete() && currentItem.fields.length > 0 && (
                            <Button
                                color="secondary"
                                sx={{marginRight: 'auto'}}
                                startIcon={<EditIcon/>}
                                onClick={handleEdit}
                            >
                                EDIT
                            </Button>
                        )}
                        {canEditOrDelete() && currentItem.fields.length > 0 && (
                            <Button
                                color="primary"
                                startIcon={<EditIcon />}
                                onClick={() => setFileEditDialogOpen(true)}
                            >
                                Edit Files
                            </Button>
                        )}
                        <Button
                            className='side-panel-footer-button'
                            variant="contained"
                            onClick={() => {
                                setShowContribution(true);
                                setIsSidePanelOpen(false);
                                setAddStoryMode(true);

                                // Extract loc_id and parse it
                                const locId = selectedItems[currentPage]?.loc_id;
                                if (locId) {
                                    // Split loc_id into lat and lng and convert to numbers
                                    const [lat, lng] = locId.split(',').map(Number);
                                    if (!isNaN(lat) && !isNaN(lng)) {
                                        setSelectedAddressLatLng({lat, lng}); // Set lat,lng if valid
                                    } else {
                                        console.warn("Invalid loc_id format:", locId);
                                    }
                                } else {
                                    console.warn("loc_id is null or undefined");
                                }

                                // Extract and set the address field
                                const addressField = selectedItems[currentPage]?.fields.find(
                                    (field) => field.field_type === "address"
                                )?.field_value;
                                if (addressField) {
                                    setSelectedAddress(addressField);
                                } else {
                                    console.warn("Address field is not found");
                                }
                            }}
                            startIcon={<AddCircleIcon />}
                        >
                            Add A Story
                        </Button>

                    </Box>
                    <Box className="navigation-controls">
                        <Button onClick={handlePrevious} disabled={currentPage === 0}>
                            Previous
                        </Button>
                        <Typography>{`${currentPage + 1} of ${selectedItems.length}`}</Typography>
                        <Button onClick={handleNext} disabled={currentPage >= selectedItems.length - 1}>
                            Next
                        </Button>
                    </Box>
                    <Typography variant='body2' color='textSecondary' className="byline">
                        Submitted by: {currentItem.user?.display_name || 'Unknown'}
                    </Typography>
                </Box>
            </Box>

            <Dialog open={imageDialogOpen} onClose={handleCloseImageDialog}>
                <DialogTitle>Image Viewer</DialogTitle>
                <DialogContent>
                    <img src={selectedImage} alt="Enlarged View" className="dialog-image" />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => window.open(selectedImage, '_blank')} color="primary">
                        Download
                    </Button>
                    <Button onClick={handleCloseImageDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={isEditing} onClose={() => setIsEditing(false)}>
                <DialogTitle>Edit Entry</DialogTitle>
                <DialogContent>
                    {/*<TextField
                            name="year"
                            label="Year"
                            value={editFormData.year || ''}
                            onChange={handleFormChange}
                            fullWidth
                            margin="normal"
                            type="number"
                        />*/}
                    {currentItem.fields.map((field, index) => (
                        <TextField
                            key={index}
                            name={field.field_name}
                            label={field.field_name}
                            value={editFormData[field.field_name] || ''}
                            onChange={handleFormChange}
                            fullWidth
                            margin="normal"
                        />
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button sx={{ marginRight: 'auto' }} color="error" startIcon={<Delete />} onClick={handleDelete}>Delete</Button>
                    <Button onClick={() => setIsEditing(false)} color="primary">Cancel</Button>
                    <Button onClick={handleSaveChanges} color="primary" startIcon={<Save />}>Save</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={fileEditDialogOpen} onClose={() => setFileEditDialogOpen(false)}>
                <DialogTitle>Edit Files</DialogTitle>
                <DialogContent>
                    <Typography>Existing Files:</Typography>
                    <Stack direction="column" spacing={1}>
                        {nonImageFiles.concat(
                            carouselFiles.map((file) => ({
                                id: file.id,
                                filepath: file.filepath,
                                filename: file.filepath?.split('/').pop(), // Generate `filename` for consistency
                            }))
                        ).map((file, idx) => (
                            <Chip
                                key={idx}
                                label={file.filename || file.filepath?.split('/').pop() || "Unknown File"}
                                onDelete={() => handleFileDelete(file)}
                            />
                        ))}
                    </Stack>
                    <Typography>Add New Files:</Typography>
                    <input type="file" multiple onChange={handleFileAdd} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setFileEditDialogOpen(false)}>Close</Button>
                    <Button onClick={uploadFiles} color="primary">Save</Button>
                </DialogActions>
            </Dialog>

        </Grid>
    );
};
export default SidePanelComponent;
