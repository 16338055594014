import React, { createContext, useContext, useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import axios from 'axios';

// Create the context
const AuthContext = createContext();

const getFirstName = (displayName) => displayName ? displayName.split(' ')[0] : '';
const getLastName = (displayName) => {
    const nameParts = displayName ? displayName.split(' ') : [];
    return nameParts.length > 1 ? nameParts.slice(1).join(' ') : '';
};

// AuthProvider component to wrap the app
export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [userFirstName, setUserFirstName] = useState('');
    const [userLastName, setUserLastName] = useState('');
    const [globalRoles, setGlobalRoles] = useState({ isAdmin: false, isDev: false });
    const [projectRoles, setProjectRoles] = useState({});
    const [loading, setLoading] = useState(true);

    // Sync user to the database
    const syncUserToDatabase = async (user) => {
        if (user) {
            const { uid, displayName, email } = user;
            try {
                const response = await axios.post('https://api.whoseland.work/syncUserToDatabase.php', { uid, displayName, email });
                if (!response.data.success) {
                    console.error('Error syncing user data:', response.data.error);
                }
            } catch (error) {
                console.error('Error syncing user data:', error);
            }
        }
    };

    // Fetch user roles and project-specific roles from the database
    const getUserRolesFromDatabase = async (uid) => {
        try {
            const response = await axios.get(`https://api.whoseland.work/fetchUserProjectRoles.php?uid=${uid}`);
            if (response.status === 200) {
                return response.data;  // Example: { globalRoles: { isAdmin: true, isDev: false }, projectRoles: { project1: 'Admin', project2: 'Viewer' } }
            } else {
                throw new Error('Error fetching user roles from database');
            }
        } catch (error) {
            console.error('Failed to retrieve user roles:', error);
            return { globalRoles: { isAdmin: false, isDev: false }, projectRoles: {} };
        }
    };

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                try {
                    // Sync the user data to the database
                    await syncUserToDatabase(user);

                    // Fetch user roles and assign global/project-specific roles
                    const { globalRoles, projectRoles } = await getUserRolesFromDatabase(user.uid);

                    // Update state with the user's global and project-specific roles
                    setUser(user);
                    setUserFirstName(getFirstName(user.displayName));
                    setUserLastName(getLastName(user.displayName));
                    setGlobalRoles(globalRoles);
                    setProjectRoles(projectRoles);

                } catch (error) {
                    console.error("Error syncing user or fetching roles:", error);
                }
            } else {
                setUser(null);
                setUserFirstName('');
                setUserLastName('');
                setGlobalRoles({ isAdmin: false, isDev: false });
                setProjectRoles({});
            }
            setLoading(false);
        });

        return () => unsubscribe(); // Clean up the listener on unmount
    }, []);

    const logout = async () => {
        const auth = getAuth();
        await signOut(auth);
        setUser(null);
        setUserFirstName('');
        setUserLastName('');
    };

    return (
        <AuthContext.Provider value={{ user, userFirstName, userLastName, globalRoles, projectRoles, loading, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

// Custom hook to use the AuthContext
export const useAuth = () => useContext(AuthContext);
