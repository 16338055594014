import React from 'react';
import { Box, List, ListItem, ListItemText, Button } from '@mui/material';

const FormSidebar = ({ forms, onSelectForm, onAddForm }) => (
    <Box sx={{ width: 300, padding: 2, borderRight: '1px solid #ddd' }}>
        <List>
            {forms && forms.length > 0 && forms.map((form) => (
                <ListItem button key={form.id} onClick={() => onSelectForm(form)}>
                    <ListItemText primary={form.form_title} secondary={form.project_name} />
                </ListItem>
            ))}
        </List>
        <Button variant="outlined" onClick={onAddForm} fullWidth>
            Add Another Form
        </Button>
    </Box>
);

export default FormSidebar;
