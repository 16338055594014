import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, List, IconButton, TextField } from '@mui/material';
import { Edit as EditIcon, AddCircle as AddCircleIcon } from '@mui/icons-material';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { SortableContext, useSortable, arrayMove } from '@dnd-kit/sortable';

const FormDetails = ({ form, isEditing, setIsEditing, onSave, onEdit, onAddField, onUpdateForm }) => {
    const [fields, setFields] = useState(form.fields || []);
    const [formTitle, setFormTitle] = useState(form.form_title || '');

    // Update fields and form title when form prop changes
    useEffect(() => {
        setFields(form.fields || []);
        setFormTitle(form.form_title || '');
    }, [form]);

    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active && over && active.id !== over.id) {
            const oldIndex = fields.findIndex((field) => field.field_id === active.id);
            const newIndex = fields.findIndex((field) => field.field_id === over.id);
            const newFields = arrayMove(fields, oldIndex, newIndex);
            setFields(newFields);

            // Update the parent component's form object with the new field order
            onUpdateForm({ ...form, fields: newFields });
        }
    };

    const handleTitleChange = (event) => {
        setFormTitle(event.target.value);
        onUpdateForm({ ...form, form_title: event.target.value });
    };

    return (
        <Box flex={1} p={2}>
            {isEditing ? (
                <TextField
                    fullWidth
                    variant="outlined"
                    label="Form Title"
                    value={formTitle}
                    onChange={handleTitleChange}
                    sx={{ marginBottom: 2 }}
                />
            ) : (
                <Typography variant="h5">{formTitle}</Typography>
            )}
            <Typography variant="subtitle1">Project: {form.project_name}</Typography>

            {isEditing ? (
                <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                    <SortableContext items={fields.map((field) => field.field_id)}>
                        <List>
                            {fields.map((field) => (
                                <SortableField key={field.field_id} field={field} />
                            ))}
                        </List>
                    </SortableContext>
                </DndContext>
            ) : (
                <List>
                    {fields.map((field) => (
                        <Box
                            key={field.field_id}
                            sx={{
                                padding: '8px',
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                marginBottom: '8px',
                            }}
                        >
                            <Typography>{field.field_name} ({field.field_type})</Typography>
                        </Box>
                    ))}
                </List>
            )}

            {isEditing ? (
                <Button variant="contained" color="primary" onClick={onSave}>
                    Save
                </Button>
            ) : (
                <IconButton onClick={onEdit}>
                    <EditIcon />
                </IconButton>
            )}
            <Button
                variant="outlined"
                startIcon={<AddCircleIcon />}
                onClick={onAddField}
                sx={{ marginTop: 2 }}
            >
                Add Field
            </Button>
        </Box>
    );
};

const SortableField = ({ field }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: field.field_id });

    const style = {
        transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
        transition,
        padding: '8px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        marginBottom: '8px',
    };

    return (
        <Box ref={setNodeRef} style={style} {...attributes} {...listeners}>
            <Typography>{field.field_name} ({field.field_type})</Typography>
        </Box>
    );
};

export default FormDetails;
