import React, {useEffect, useState} from 'react';
import {
    Box,
    Typography,
    Grid,
    Button,
    MenuItem,
    Modal,
    Stack,
    ListItem,
    List,
    ListItemIcon,
    ListItemText
} from '@mui/material';
import { Link } from 'react-router-dom';
import {Avatar, ListItemButton, Menu} from "@mui/joy";
import {useAuth} from "./authContext";
import AuthForm from "./authForm";
import {styled} from '@mui/material/styles';
import MuiDrawer, { drawerClasses } from '@mui/material/Drawer';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import LayersIcon from '@mui/icons-material/Layers';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CommentIcon from '@mui/icons-material/Comment';
import WebIcon from '@mui/icons-material/Web';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import AdminProjectManager from "./AdminProjectsManager";
import CommentAdmin from "./CommentAdmin";
import UpdateMarkers from "./UpdateMarkers";
import MapLayerAdmin from "./MapLayerAdmin";
import {calculateNewValue} from "@testing-library/user-event/dist/utils";
import RoleManagement from "./RoleManagement";
import AdminHome from "./AdminHome";
import axios from "axios";
import FormsAdmin from "./FormsAdmin";

const AdminDashboard = () => {
    const [showAdminHome, setShowAdminHome] = useState(true);
    const [showLayersAdmin, setShowLayersAdmin] = useState(false);
    const [showFormsAdmin, setShowFormsAdmin] = useState(false);
    const [showUpdateAdmin, setShowUpdateAdmin] = useState(false);
    const [showCommentAdmin, setShowCommentAdmin] = useState(false);
    const [showProjectManager, setShowProjectManager] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { user, loading, logout, globalRoles, projectRoles} = useAuth();
    const [selectedItem, setSelectedItem] = useState(null);
    const [showUserManager, setShowUserManager] = useState(false);
    const [projects, setProjects] = useState([]);
    const [projectAdmin, setProjectAdmin] = useState([]);

    const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility

    const globalAdmin = () => {
        return (
            (globalRoles && globalRoles.isAdmin) || (globalRoles && globalRoles.isDev)
        );
    };



    const fetchProjects = () => {
      axios.get('https://api.whoseland.work/fetchAllProjects.php')
        .then((response) => {
            setProjects(response.data.projects);
        })
        .catch(error => console.error('Error fetching projects:', error));
    };
    useEffect(() => {
        fetchProjects();
    }, []);

    const handleLogin = async () => {
        try {
            setIsModalOpen(true);
        } catch (error) {
            console.error('Error opening login form:', error);
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false); // Close the modal
    };




    const handleMenuClick = (event) => {
        // Toggle the menu: close it if already open, open it if closed
        if (anchorEl) {
            setAnchorEl(null); // Menu is open, close it
        } else {
            setAnchorEl(event.currentTarget); // Menu is closed, open it
        }
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const drawerWidth = 240;

    const Drawer = styled(MuiDrawer)({
        width: drawerWidth,
        flexShrink: 0,
        boxSizing: 'border-box',
        mt: 10,
        [`& .${drawerClasses.paper}`]: {
            width: drawerWidth,
            boxSizing: 'border-box',
        },
    });
    const mainListItems = [
        { text: 'Home', icon: <HomeRoundedIcon />, stateKey: 'showAdminHome' },
        { text: 'Relocate Markers', icon: <MultipleStopIcon />},
        { text: 'Project Manager', icon: <WebIcon />, stateKey: 'showProjectManager' },
        { text: 'Comment Manager', icon: <CommentIcon />, stateKey: 'showCommentAdmin' },
        { text: 'Map Layers Manager', icon: <LayersIcon />, stateKey: 'showLayersAdmin' },
        { text: 'Force Update Markers', icon: <LocationOnIcon />, stateKey: 'showUpdateAdmin' },
        { text: 'User Role Management', icon: <PeopleRoundedIcon />, stateKey: 'showUserManager' },
        { text: 'Forms Admin', icon: <WebIcon />, stateKey: 'showFormsAdmin' },
    ];

    const handleComponentToggle = (stateKey) => {
        // If the selected item is clicked again, close it
        if (selectedItem === stateKey) {
            setSelectedItem(null);
            setShowLayersAdmin(false);
            setShowCommentAdmin(false);
            setShowProjectManager(false);
            setShowUserManager(false);
            setShowFormsAdmin(false);
            setShowAdminHome(true)
            return;
        }

        setSelectedItem(stateKey); // Set the currently selected item

        // Reset all other states to false
        setShowLayersAdmin(false);
        setShowCommentAdmin(false);
        setShowProjectManager(false);
        setShowUserManager(false);
        setShowAdminHome(false);
        setShowFormsAdmin(false);

        // Open the selected component
        switch (stateKey) {
            case 'showAdminHome':
                setShowAdminHome(true);
                break;
            case 'showLayersAdmin':
                setShowLayersAdmin(true);
                break;
            case 'showCommentAdmin':
                setShowCommentAdmin(true);
                break;
            case 'showProjectManager':
                setShowProjectManager(true);
                break;
            case 'showUserManager':
                setShowUserManager(true);
                break;
            case 'showFormsAdmin':
                setShowFormsAdmin(true);
                break;
            default:
                break;
        }
    };

    return (
        <Box sx={{ height: '100vh', width: '100vw', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Modal
                open={isModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="login-modal"
                aria-describedby="login-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '55%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        pt: 0,
                        pb: 4,
                        pr: 4,
                        pl: 4,
                        borderRadius: 2,
                    }}
                >
                    <AuthForm onSuccess={handleCloseModal} />
                </Box>
            </Modal>
            <Box sx={{ height: '90px', backgroundColor: 'black', width: '100vw', position: 'relative' }}>
                <img src={'./LOGO-5.png'} alt="Logo" style={{ height: '50px', position: 'absolute', top: 20, left: 80 }} />
                <Box sx={{ position: 'absolute', left: 350, top: 30 }}>
                    <Typography variant="h5" sx={{ color: "#f5f5f5" }}>Whoseland Mapping Admin Console</Typography>
                </Box>
                <Box sx={{ position: 'absolute', top: 10, right: 10, zIndex: 1000 }}>
                    {loading ? (
                        <Typography>Loading...</Typography>
                    ) : user ? (
                        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                            <Typography variant="body1" sx={{ marginRight: 2, color: "#f5f5f5" }}>
                                Welcome, {user.displayName || user.email}
                            </Typography>
                            <Button variant="contained" color="secondary" onClick={logout}>
                                Logout
                            </Button>
                        </Box>
                    ) : (
                        <Button variant="contained" color="primary" onClick={handleLogin}>
                            Login
                        </Button>
                    )}
                </Box>
            </Box>
            <Grid container spacing={1} sx={{ width: "100%", height: '100%', overflow: 'auto' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', position: 'absolute', top: 83, left: 0, width: 275, height: "calc(100% - 90px)" }}>
                    <Drawer
                        variant="permanent"
                        sx={{
                            [`& .${drawerClasses.paper}`]: {
                                backgroundColor: 'background.paper',
                                top: 'auto',
                                width: '100%',
                                position: 'relative',
                            },
                            top: 'auto',
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                mt: 'calc(var(--template-frame-height, 0px) + 4px)',
                                p: .25,
                            }}
                        >

                        </Box>
                        <Stack sx={{ flexGrow: 1, p: 1, justifyContent: 'space-between' }}>
                            <List dense>
                                {mainListItems.map((item, index) => (
                                    <ListItem key={index} sx={{ display: 'block', paddingLeft: 0, paddingRight: 0, paddingBottom: 1, paddingTop: 1 }}>
                                        <ListItemButton
                                            onClick={() => handleComponentToggle(item.stateKey)}
                                            sx={{ backgroundColor: selectedItem === item.stateKey ? 'lightgray' : 'transparent' }}
                                        >
                                            <ListItemIcon>{item.icon}</ListItemIcon>
                                            <ListItemText primary={item.text} />
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>
                            <List dense>
                                    <ListItem  disablePadding sx={{ display: 'block' }}>
                                        <ListItemButton>
                                            <ListItemIcon><InfoRoundedIcon /></ListItemIcon>
                                            <ListItemText primary='About'></ListItemText>
                                        </ListItemButton>
                                    </ListItem>
                            </List>
                        </Stack>
                        <Stack
                            direction="row"
                            sx={{
                                p: 2,
                                gap: 1,
                                alignItems: 'center',
                                borderTop: '1px solid',
                                borderColor: 'divider',
                            }}
                        >

                            <Avatar
                                sizes="small"
                                alt={user?.displayName || 'N/A'}
                                sx={{ width: 36, height: 36 }}
                            />
                            <Box sx={{ mr: 'auto' }}>
                                <Typography variant="body2" sx={{ fontWeight: 500, lineHeight: '16px' }}>
                                    {user?.displayName || 'N/A'}
                                </Typography>
                                <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                                    {user?.email || 'N/A'}
                                </Typography>
                                {globalRoles.isDev === true &&
                                    <Typography variant="caption" sx={{ color: 'text.secondary'}}>
                                        <br /> Website Developer
                                    </Typography>
                                }
                                {globalRoles.isAdmin === true &&
                                    <Typography variant="caption" sx={{ color: 'text.secondary'}}>
                                        <br /> Website Administrator
                                    </Typography>
                                }
                                {projectRoles && projectRoles.length > 0 &&
                                    projectRoles.map((project, index) => (
                                        <ListItem key={index} disablePadding>
                                            <Typography variant="caption" sx={{ color: 'text.secondary'}}>{project}</Typography>
                                        </ListItem>
                                    ))
                                }
                            </Box>
                        </Stack>
                    </Drawer>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', position: 'absolute', top: 83, right: 0, width: "calc(100% - 275px)", height: "calc(100% - 90px)", overflow: 'auto' }}>
                    {showAdminHome && <AdminHome />}
                    {showLayersAdmin && <MapLayerAdmin />}
                    {showFormsAdmin && <FormsAdmin />}
                    {globalAdmin() && showCommentAdmin ? <CommentAdmin /> : showCommentAdmin && !globalAdmin() && <div>Additional Admin Privileges Required to View Page</div>}
                    {globalAdmin() && showProjectManager ? <AdminProjectManager /> : showProjectManager && !globalAdmin() && <div>Additional Admin Privileges Required to View Page</div>}
                    {globalAdmin() && showUserManager ? <RoleManagement /> : showUserManager && !globalAdmin() && <div>Global Site Admin Privileges Required to Manage Users</div>}
                </Box>
            </Grid>
        </Box>
    );
};

export default AdminDashboard;
