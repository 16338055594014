import React, { useContext, useEffect, useState, useRef, useCallback } from 'react';
import {
    MapContainer,
    TileLayer,
    Marker,
    Popup,
    useMap,
    CircleMarker,
    ImageOverlay,
    ImageOverlayProps,
    LayersControl,
    useMapEvents,
    Polygon
} from 'react-leaflet';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import {Grid, Typography, Button, IconButton, Paper, TextField, Autocomplete, Modal, MenuItem} from "@mui/material";
import 'leaflet/dist/leaflet.css';
import './decade_map.css';
import './web19201.css';
import ContributionComp from "./ContributionComp";
import baseLayerOptions from './baseLayerOptions';
import { useUserPreferences } from './UserPreferencesContext';
import Preferences from './Preferences';
import WelcomePopup from "./WelcomePopup";
import ErrorBoundary from './ErrorBoundary';
import AuthForm from './authForm';
import { useAuth } from './authContext';
import SidePanelComponent from "./SidePanelComponent";
import { useProject } from './ProjectContext';
import { useNavigate } from 'react-router-dom';
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import InfoIcon from '@mui/icons-material/Info';
import LayersIcon from '@mui/icons-material/Layers';
import axios from "axios";
import 'leaflet-draw/dist/leaflet.draw.css';
import { FeatureGroup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import L from 'leaflet';
import {NavigateBefore, NavigateNext} from "@mui/icons-material";





function valuetext(value) {
    return `${value}`;
}

function ArrowBackIosIcon() {
    return null;
}

function ArrowForwardIosIcon() {
    return null;
}

function MapComponent() {
    const { preferences } = useUserPreferences();
    const [layerUrl, setLayerUrl] = useState(baseLayerOptions[preferences.baseLayer].url);
    const [layerAttribution, setLayerAttribution] = useState(baseLayerOptions[preferences.baseLayer].attribution);
    const [layerMaxZoom, setLayerMaxZoom] = useState(baseLayerOptions[preferences.baseLayer].maxZoom);
    const [layerMaxNativeZoom, setLayerMaxNativeZoom] = useState(baseLayerOptions[preferences.baseLayer].maxNativeZoom);
    const [layerSubdomains, setLayerSubdomains] = useState(baseLayerOptions[preferences.baseLayer].subdomains || []);
    const mapRef = useRef();
    const { globalRoles, projectRoles } = useAuth();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { project } = useProject();
    const [mapCenter, setMapCenter] = useState([project.center_lat, project.center_lng]);
    const [zoom, setZoom] = useState(project.zoom_level);
    const navigate = useNavigate();


    useEffect(() => {
        if (project.center_lat && project.center_lng && project.zoom_level) {
            setMapCenter([project.center_lat, project.center_lng]);
            setZoom(project.zoom_level);
        }
    }, [project]);


    useEffect(() => {
        const baseLayer = baseLayerOptions[preferences.baseLayer];
        setLayerUrl(baseLayer.url);
        setLayerAttribution(baseLayer.attribution);
        setLayerMaxZoom(baseLayer.maxZoom);
        setLayerMaxNativeZoom(baseLayer.maxNativeZoom);
        setLayerSubdomains(baseLayer.subdomains || []);
    }, [preferences.baseLayer]);

    const [year, setYear] = useState(1950);
    const [mapdata, setMapdata] = useState([]);
    const [showContribution, setShowContribution] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState('');
    const [selectedAddressLatLng, setSelectedAddressLatLng] = useState(null)
    const [selectMode, setSelectMode] = useState(false);
    const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [markerId, setMarkerId] = useState([]);
    const [currentZoom, setCurrentZoom] = useState(zoom);
    const [addressYearMap, setAddressYearMap] = useState({});
    const [showPreferences, setShowPreferences] = useState(false);
    const [showLayersAdmin, setShowLayersAdmin] = useState(false);
    const [showUpdateAdmin, setShowUpdateAdmin] = useState(false);
    const [showCommentAdmin, setShowCommentAdmin] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [searchData, setSearchData] = useState([]);
    const [triggeredByHowItWorks, setTriggeredByHowItWorks] = useState(false);
    const [overlayOpacity, setOverlayOpacity] = useState(1);
    const [overlays, setOverlays] = useState([]);
    const [openPopup, setOpenPopup] = useState(false);
    const [showOverlayOpacity, setShowOverlayOpacity] = useState(true);
    const [locations, setLocations] = useState([]); // Will store fetched cont data
    const [selectedMarkerIds, setSelectedMarkerIds] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [addStoryMode, setAddStoryMode] = useState(false);
    const [polygons, setPolygons] = useState([]);
    const [isDrawingPolygon, setIsDrawingPolygon] = useState(false);
    const [polygonCoordinates, setPolygonCoordinates] = useState([]);
    const [polygonData, setPolygonData] = useState(null);
    const scrollContainerRef = useRef(null);
    const [hasScrolled, setHasScrolled] = useState(false);





    const adminOrMod = () => {
        const projectAdminRole = project.projectid + '_Admin'; // Construct the specific project admin role

        return (
            globalRoles?.isAdmin || // Optional chaining in case globalRoles is undefined
            globalRoles?.isDev || // Optional chaining for Dev role
            (projectRoles && projectRoles.length > 0 && projectRoles.includes(projectAdminRole)) // Check for project-specific admin role
        );
    };

    useEffect(() => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft = scrollContainerRef.current.scrollWidth;
        }
    }, []);


    function getDistanceFromLatLng(lat1, lng1, lat2, lng2) {
        const R = 6371; // Radius of the Earth in kilometers
        const dLat = (lat2 - lat1) * (Math.PI / 180);
        const dLng = (lng2 - lng1) * (Math.PI / 180);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
            Math.sin(dLng / 2) * Math.sin(dLng / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return R * c; // Distance in kilometers
    }


    useEffect(() => {
        const shouldShowPopup = localStorage.getItem('showWelcomePopup');
        if (shouldShowPopup !== 'false') {
            setOpenPopup(true);
        }
    }, []);

    const marks = [
        {
            value: 1760,
            label:
                <Paper elevation={2} radius={3}
                       sx={{paddingTop: '3px', paddingBottom: '3px', paddingLeft: '5px', paddingRight: '5px', filter: openPopup === true ? 'grayscale(100) opacity(1) brightness(0.5)' : 'none'}}>
                    <Typography variant="body2">
                        Pre-1770
                    </Typography>
                </Paper>
        },
        { value: 1770, label: '1770s' },
        { value: 1780, label: '1780s' },
        { value: 1790, label: '1790s' },
        { value: 1800, label: '1800s' },
        { value: 1810, label: '1810s' },
        { value: 1820, label: '1820s' },
        { value: 1830, label: '1830s' },
        { value: 1840, label: '1840s' },
        { value: 1850, label: '1850s' },
        { value: 1860, label: '1860s' },
        { value: 1870, label: '1870s' },
        { value: 1880, label: '1880s' },
        { value: 1890, label: '1890s' },
        { value: 1900, label: '1900s' },
        { value: 1910, label: '1910s' },
        { value: 1920, label: '1920s' },
        { value: 1930, label: '1930s' },
        { value: 1940, label: '1940s' },
        { value: 1950, label: '1950s' },
        { value: 1960, label: '1960s' },
        { value: 1970, label: '1970s' },
        { value: 1980, label: '1980s' },
        { value: 1990, label: '1990s' },
        { value: 2000, label: '2000s' },
        { value: 2010, label: '2010s' },
        { value: 2020, label: '2020s' },
        {
            value: 2030,
            label:
                <Paper elevation={2} radius={3}
                       sx={{paddingTop: '3px', paddingBottom: '3px', paddingLeft: '5px', paddingRight: '5px', filter: openPopup === true ? 'grayscale(100) opacity(1) brightness(0.5)' : 'none'}}>
                    <Typography variant="body2">
                        Vision
                    </Typography>
                </Paper>
        },
    ];
    const fetchLocations = async () => {
        try {
            const response = await axios.get(`https://api.whoseland.work/selectProjectCont.php?projectsid=${project.projectsid}`);
            setLocations(response.data.locations);
            setPolygons(response.data.polygons);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchLocations();
    }, [project.projectsid])


    const handleMenuClick = (event) => {
        // Toggle the menu: close it if already open, open it if closed
        if (anchorEl) {
            setAnchorEl(null); // Menu is open, close it
        } else {
            setAnchorEl(event.currentTarget); // Menu is closed, open it
        }
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };


    const handleOverlayOpacityChange = (event, newValue) => {
        setOverlayOpacity(newValue);
    };
    const handleSearch = (query) => {
        if (!query || !searchData || searchData.length === 0) {
            setSearchResults([]);
            return;
        }

        const results = [];

        searchData.forEach((item) => {
            if (!item) return;
            const addressMatch = item.address && item.address.toLowerCase().includes(query.toLowerCase());
            const keywordMatch = Object.entries(item).find(([key, value]) =>
                value && typeof value === 'string' && value.toLowerCase().includes(query.toLowerCase())
            );

            if (addressMatch || keywordMatch) {
                const keywordResult = keywordMatch ? `${keywordMatch[0]}: ${keywordMatch[1]}` : '';
                const year = item.year ? parseInt(item.year, 10) : (item.source_table ? parseInt(item.source_table.split('_')[1], 10) : null);
                results.push({
                    address: item.address,
                    idadd: item.idadd,
                    lat: item.lat,
                    lng: item.lng,
                    year: year,
                    keyword: keywordResult
                });
            }
        });

        setSearchResults(results);
    };

    const handleSearchSelect = (event, value) => {
        if (value) {
            //fetchDataForMarker(value.idadd, value.address)
            setSelectedMarker(value.idadd);
            setSelectedAddress(value.address);
            setYear(parseInt(value.year, 10));
            mapRef.current.setView([value.lat, value.lng], zoom);
            setIsSidePanelOpen(true);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearch(event.target.value);
        }
    };



    const MapEventsHandler = () => {

        const map = useMap();  // get a reference to the map instance

        useEffect(() => {
            const leafletContainer = map.getContainer();
            if (selectMode) {
                leafletContainer.classList.add('selecting');
            } else {
                leafletContainer.classList.remove('selecting');
            }
        }, [map]);
        useMapEvents({
            zoomend: () => {
                setCurrentZoom(map.getZoom());
            },
            click: async (e) => {
                if (selectMode) {
                    const apiKey = "AIzaSyCllzLZfdBPfaSa65Y4vwTIrONb3IuZ4ac";
                    const { lat, lng } = e.latlng;
                    const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`);
                    if (response.ok) {
                        const geocodeData = await response.json();
                        if (geocodeData.results && geocodeData.results.length > 0) {
                            const address = geocodeData.results[0].formatted_address;
                            setSelectedAddress(address);
                            setSelectedAddressLatLng(e.latlng)
                            setSelectMode(false); // reset selectMode after successful selection
                        } else {
                            console.error('No results found for these coordinates');
                        }
                    } else {
                        console.error(`Geocoding error: ${response.status}`);
                    }
                }
            }
        });
        return null;

    };



    useEffect(() => {
        const geocodeAddress = async () => {
            const apiKey = "AIzaSyCllzLZfdBPfaSa65Y4vwTIrONb3IuZ4ac";
            const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(selectedAddress)}&key=${apiKey}`);
            if (response.ok) {
                const geocodeData = await response.json();
                if (geocodeData.results && geocodeData.results.length > 0) {
                    const { geometry } = geocodeData.results[0];
                    setSelectedAddressLatLng({lat: geometry.location.lat, lng: geometry.location.lng});
                } else {
                    console.error('No results found for this address');
                }
            } else {
                console.error(`Geocoding error: ${response.status}`);
            }
        }

        if (selectedAddress) {
            geocodeAddress();
        }
    }, [selectedAddress]);



    function calculateRadius(currentZoom) {
        // Ensure currentZoom is a valid number
        if (!currentZoom || isNaN(currentZoom)) {
            return 3.5;  // Default radius if zoom is invalid
        }

        // Return a minimum radius for low zoom levels
        if (currentZoom < 16) {
            return 3.5;
        }

        // Calculate radius for higher zoom levels and ensure it's positive
        const radius = (currentZoom / 2) - 4;
        return radius > 0 ? radius : 3.5;  // Ensure a minimum radius of 3.5
    }


    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.invalidateSize();
        }
    }, [isSidePanelOpen, showLayersAdmin, showCommentAdmin, showUpdateAdmin, showContribution, showPreferences]);


    const handleShowPopup = () => {
        setOpenPopup(true);
        setTriggeredByHowItWorks(true);
    };

    const handleClosePopup = () => {
        setOpenPopup(false);
    };

    const { user, loading, logout } = useAuth();
    const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility

    const handleLogin = async () => {
        try {
            setIsModalOpen(true);
        } catch (error) {
            console.error('Error opening login form:', error);
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false); // Close the modal
    };

    const [overlayLayers, setOverlayLayers] = useState([]);
    const [selectedLayers, setSelectedLayers] = React.useState([]);

    useEffect(() => {
        if (project) {
            fetch(`https://api.whoseland.work/fetchOverlayCity.php?projectid=${project.projectid}`)
                .then((response) => response.json())
                .then((data) => {
                    if (Array.isArray(data)) {
                        setOverlayLayers(data); // Set state if data is valid
                    } else {
                        console.error('Expected an array but got:', data);
                    }
                })
                .catch((error) => console.error("Error fetching overlay layers: ", error));
        }
        else {
            fetch('https://api.whoseland.work/fetchOverlay.php')
                .then((response) => response.json())
                .then((data) => {
                    if (Array.isArray(data)) {
                        setOverlayLayers(data); // Set state if data is valid
                    } else {
                        console.error('Expected an array but got:', data);
                    }
                })
                .catch((error) => console.error("Error fetching overlay layers: ", error));
        }

    }, []);
    const getGridSize = (isSidePanelOpen, showPreferences, showLayersAdmin, showCommentAdmin) => {
        if (isSidePanelOpen) return { xs: 0, sm: 6, md: 8 };
        if (showPreferences) return { xs: 0, sm: 6, md: 8 };
        if (showContribution) return { xs: 0, sm: 6, md: 8 };
        if (showLayersAdmin) return { xs: 0, sm: 0, md: 0 };
        if (showCommentAdmin) return { xs: 0, sm: 0, md: 0 };
        if (showUpdateAdmin) return { xs: 0, sm: 0, md: 0 };
        return { xs: 12, sm: 12, md: 12 }; // Default case
    };
    const handleMarkerClick = (clickedMarkerIds) => {
        // Get the lat and lng of the clicked marker
        setPolygonData(null);
        const clickedLocation = locations.find(loc => loc.ids.join('-') === clickedMarkerIds.join('-'));

        if (!clickedLocation) return; // If clicked location is not found, exit

        const { loc_lat: clickedLat, loc_lng: clickedLng } = clickedLocation;
        const radius = 0.01; // Define your desired radius in kilometers

        // Find all marker IDs within the radius
        const nearbyMarkerIds = locations
            .filter(loc => {
                const distance = getDistanceFromLatLng(clickedLat, clickedLng, loc.loc_lat, loc.loc_lng);
                return distance <= radius;
            })
            .map(loc => loc.ids);

        setSelectedMarkerIds(nearbyMarkerIds);
        setSelectedMarker(clickedMarkerIds)
        setSelectedAddressLatLng({ lat: clickedLat, lng: clickedLng });
        setIsSidePanelOpen(true);
    };
    const handlePolygonCreated = (e) => {
        const layer = e.layer;
        const latlngs = layer.getLatLngs()[0]; // Get the coordinates from the drawn polygon
        const coordinates = latlngs.map(latlng => [latlng.lat, latlng.lng]); // Convert to array of [lat, lng]
        setPolygonCoordinates(coordinates); // Store the coordinates
    };

    const activatePolygonDrawing = () => {
        setIsDrawingPolygon(true);
    };

    const handlePolygonClick = (polygon) => {
        const { coordinates, id } = polygon;

        // Calculate the center of the polygon to zoom in
        const bounds = L.latLngBounds(coordinates);
        const center = bounds.getCenter();

        // Zoom into the polygon
        mapRef.current.setView([center.lat, center.lng], Math.max(zoom, 18));

        // Find marker IDs within the polygon bounds
        const markerIdsInPolygon = locations
            .filter(loc => bounds.contains([loc.loc_lat, loc.loc_lng]))
            .map(loc => loc.ids)
            .flat();

        // Open the side panel and pass polygon data
        setSelectedMarkerIds(markerIdsInPolygon);
        setPolygonData({ id, coordinates });
        setIsSidePanelOpen(true);
    };

    // Function to scroll the container left
    const scrollLeft = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft -= 150; // Adjust the value as needed
        }
    };

    // Function to scroll the container right
    const scrollRight = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft += 150; // Adjust the value as needed
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', width: '100vw' }}>
            <WelcomePopup open={openPopup} onClose={handleClosePopup} triggeredByHowItWorks={triggeredByHowItWorks} />
            <Modal
                open={isModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="login-modal"
                aria-describedby="login-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '55%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        pt: 0,
                        pb: 4,
                        pr: 4,
                        pl: 4,
                        borderRadius: 2,
                    }}
                >
                    <AuthForm onSuccess={handleCloseModal} />
                </Box>
            </Modal>
            <Box sx={{ height: '90px', backgroundColor: 'black', width: '100%', position: 'relative' }}>
                <img src={project?.logo_url || './LOGO-5.png'} alt="Logo" style={{ height: '50px', position: 'absolute', top: 20, left: 80 }} />
                <Box sx={{ position: 'absolute', left: 300, top: 30 }}>
                    <Typography variant="h5" sx={{ color: "#f5f5f5" }}>{project?.project_name || 'Whoseland Archival Map'}</Typography>
                </Box>
                <Box sx={{ position: 'absolute', top: 0, right: 0, zIndex: 1000, display: 'flex', alignContent: 'center', alignItems:'center', justifyContent: 'center', height: '100%', width: 200 }}>
                    {loading ? (
                        <Typography>Loading...</Typography>
                    ) : user ? (
                        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                            <Typography variant="body1" sx={{ marginRight: 2, color: "#f5f5f5" }}>
                                Welcome, {user.displayName || user.email}
                            </Typography>
                            <Button startIcon={<LogoutIcon />} variant="contained" color="secondary" onClick={logout}>
                                Logout
                            </Button>
                        </Box>
                    ) : (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleLogin}
                            sx={{ height: 50 }}
                            startIcon={<LoginIcon />}
                        >
                            Login
                        </Button>
                    )}
                </Box>
            </Box>
            <Box sx={{ width: "100%", position: 'relative', overflow: 'hidden', boxSizing: 'border-box', }}>
                <Grid container spacing={1} alignItems="center" sx={{ backgroundColor: '#f5f5f5', borderRadius: '0px', paddingLeft: '0px', paddingRight: '0px', paddingTop: '0px', paddingBottom: '20px', margin: 0 }}>
                    <Grid item xs={3} sm={2} md={2} sx={{ display: 'flex', alignItems: 'center', paddingLeft: '8px', paddingRight: '0px', justifyContent: 'center' }}>
                        {adminOrMod() && (
                            <div>
                                <Button
                                    aria-controls="admin-menu"
                                    aria-haspopup="true"
                                    onClick={() => navigate('/admin')}
                                    variant="contained"
                                    color="primary"
                                >
                                    Admin Console
                                </Button>
                            </div>

                        )}
                    </Grid>
                    <Grid item xs={6} sm={8} md={8} sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        justifyItems: 'center',
                        position: 'relative',
                        zIndex: 10000
                    }}>
                        <IconButton
                            onClick={scrollLeft}
                        >
                            <NavigateBefore />
                        </IconButton>
                        <div
                            ref={scrollContainerRef}
                            style={{
                                overflowX: 'auto',
                                overflowY: 'hidden',
                                width: '80%',
                                whiteSpace: 'nowrap',
                                scrollbarWidth: 'none',
                                msOverflowStyle: 'none'
                        }}
                        >
                            {!isEditing && (
                                <Slider
                                    aria-label="Time-Slider"
                                    defaultValue={1950}
                                    getAriaValueText={valuetext}
                                    min={1760}
                                    max={2030}
                                    step={10}
                                    value={year}
                                    marks={marks}
                                    sx={{ width: '1400px', display: 'inline-block', paddingRight: '30px', paddingLeft: '30px', marginLeft: '50px', marginRight: '50px' }}
                                    onChange={(e, newValue) => setYear(newValue)}
                                />
                            )}
                        </div>
                        <IconButton
                            onClick={scrollRight}
                        >
                            <NavigateNext />
                        </IconButton>
                    </Grid>

                    <Grid item xs={3} sm={2} md={2} sx={{ display: 'flex', alignItems: 'center', paddingRight: '16px', justifyContent: 'center' }}>
                        {isSidePanelOpen &&
                            <Button
                                variant="contained"
                                onClick={() => { setShowContribution(true); setIsSidePanelOpen(false) }}
                                startIcon={<AddLocationAltIcon />}
                            >
                                Add New Place
                            </Button>
                        }
                        {!isSidePanelOpen &&
                            <Button
                                variant="contained"
                                onClick={() => { setShowContribution(true); setIsSidePanelOpen(false) }}
                                startIcon={<AddLocationAltIcon />}
                            >
                                Add A Place
                            </Button>
                        }
                    </Grid>
                </Grid>
            </Box>

            <Grid container sx={{ height: '100%', overflow: 'hidden', boxSizing: 'border-box', }}>
                {/* Map Section */}
                <Grid
                    item
                    {...getGridSize(isSidePanelOpen, showPreferences, showLayersAdmin, showCommentAdmin)}
                    sx={{ position: 'relative', height: '100%' }}
                >
                    <Box sx={{ position: 'absolute', bottom: 24, right: 20, zIndex: 1000 }}>
                        <Button variant="contained"
                                onClick={handleShowPopup}
                                startIcon={<InfoIcon />}
                        >
                            About Map
                        </Button>
                    </Box>
                    <Box sx={{ position: 'absolute', bottom: 75, right: 20, zIndex: 1000 }}>
                        <Button
                            variant="contained"
                            onClick={() => setShowPreferences(!showPreferences)}
                            startIcon={<LayersIcon />}
                        >
                            Map Layers
                        </Button>
                    </Box>
                    {mapCenter && zoom ? (
                        <MapContainer
                            center={mapCenter}
                            zoom={zoom}
                            ref={mapRef}
                            style={{ height: '100%', width: '100%' }}
                        >
                            <TileLayer
                                url={baseLayerOptions[preferences.baseLayer].url}
                                attribution={baseLayerOptions[preferences.baseLayer].attribution}
                                maxZoom={baseLayerOptions[preferences.baseLayer].maxZoom}
                                maxNativeZoom={baseLayerOptions[preferences.baseLayer].maxNativeZoom}
                            />

                            {Array.isArray(selectedLayers) && selectedLayers.map((layer, index) => (
                                    <TileLayer
                                        key={layer.id}
                                        url={layer.url}
                                        maxZoom={layer.max_zoom || 20}
                                        maxNativeZoom={layer.max_native_zoom || 20}
                                        opacity={overlayOpacity}
                                    />
                            ))}
                            {/*<FeatureGroup>
                                <EditControl
                                    position="topright"
                                    onCreated={handlePolygonCreated}
                                    draw={{
                                        rectangle: false,
                                        circle: false,
                                        circlemarker: false,
                                        marker: false,
                                        polyline: false,
                                        polygon: isDrawingPolygon, // Enable polygon drawing based on state
                                    }}
                                />
                            </FeatureGroup>*/}


                            {Array.isArray(locations) && locations.length > 0 && locations.map((loc) => {
                                // Check if both loc_lat and loc_lng are defined, and loc_id is not null
                                if (
                                    typeof loc.loc_lat !== 'undefined' &&
                                    typeof loc.loc_lng !== 'undefined' &&
                                    loc.loc_lat !== null &&
                                    loc.loc_lng !== null
                                ) {
                                    // Determine the marker color: blue if any year in loc.years matches the current year
                                    const markerColor = loc.years.includes(year) ? 'blue' : 'gray';


                                    return (
                                        <CircleMarker
                                            key={loc.ids.join('-')} // Use a unique key for the group of ids
                                            center={[loc.loc_lat, loc.loc_lng]}
                                            eventHandlers={{
                                                click: () => handleMarkerClick(loc.ids),
                                            }}
                                            pathOptions={{
                                                color: selectedMarker === loc.ids ? 'red' : markerColor,
                                                fillColor: selectedMarker === loc.ids ? 'red' : markerColor,
                                            }}
                                            radius={
                                                selectedMarker === loc.ids
                                                    ? calculateRadius(currentZoom) + 2
                                                    : calculateRadius(currentZoom)
                                            }
                                            fillOpacity={1}
                                            weight={0}
                                        />
                                    );
                                } else {
                                    console.warn(`Skipping location with missing lat/lng for id: ${loc.ids}`);
                                    return null; // Prevent rendering a marker for locations with undefined lat/lng
                                }
                            })}
                            {/*{
                                polygons.length > 0 && polygons.map((polygon) => {
                                    if (polygon.year === year) { // Only render polygons for the selected year
                                        return (
                                            <Polygon
                                                key={polygon.id}
                                                positions={polygon.coordinates}
                                                pathOptions={{color: 'green', fillOpacity: 0.4}}
                                                eventHandlers={{
                                                    click: () => handlePolygonClick(polygon), // Handle polygon click
                                                }}
                                            />
                                        );
                                    }
                                    return null; // Return null if the polygon year doesn't match the selected year
                                })
                            }*/}



                            {selectedAddressLatLng &&
                                <CircleMarker
                                    center={selectedAddressLatLng}
                                    pathOptions={{ color: "red", fillColor: "red" }}
                                    fillOpacity={1}
                                    radius={6}
                                    weight={0}
                                />
                            }
                            <MapEventsHandler />
                        </MapContainer>
                    ) : (
                        <div>Loading map...</div>
                    )}
                    {!isSidePanelOpen && !showContribution && showOverlayOpacity && (
                        <Box sx={{ position: 'absolute', bottom: 16, left: '50%', transform: 'translateX(-50%)', width: '40%', zIndex: 100000, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Paper elevation={2} sx={{ width: '100%', filter: openPopup === true ? 'grayscale(100) opacity(1) brightness(0.5)' : 'none', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Typography variant='h6'>Map Overlay Opacity</Typography>
                                <Slider
                                    value={overlayOpacity}
                                    min={0}
                                    max={1}
                                    step={0.01}
                                    onChange={handleOverlayOpacityChange}
                                    valueLabelDisplay="auto"
                                    sx={{ width: '85%' }}
                                />
                            </Paper>
                        </Box>
                    )}
                </Grid>

                {showPreferences && (
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{ backgroundColor: '#f5f5f5', overflowY: 'auto', position: 'relative' }}
                    >
                    <ErrorBoundary>
                        <Box sx={{ width:"100%", height: '100%', zIndex: 1000 }}>
                            <Preferences setShowPreferences={setShowPreferences} overlayLayers={overlayLayers} selectedLayers={selectedLayers} setSelectedLayers={setSelectedLayers} />
                        </Box>
                    </ErrorBoundary>
                    </Grid>
                )}
                {showContribution && (
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{ backgroundColor: '#f5f5f5', overflowY: 'auto', position: 'relative' }}
                    >
                        <ErrorBoundary>
                            <Box sx={{ width:"100%", height: '100%', zIndex: 1000 }}>
                                <ContributionComp
                                    setShowContribution={setShowContribution}
                                    selectedAddress={selectedAddress}
                                    setSelectMode={setSelectMode}
                                    setSelectedAddress={setSelectedAddress}
                                    clearSelectedAddress={() => {
                                        setSelectedAddress('');
                                        setSelectedAddressLatLng(null);
                                    }}
                                    year={year}
                                    setYear={setYear}
                                    setSelectedMarker={setSelectedMarker}
                                    selectedAddressLatLng={selectedAddressLatLng}
                                    refreshData={fetchLocations}
                                    addStoryMode={addStoryMode}
                                    setAddStoryMode={setAddStoryMode}
                                    activatePolygonDrawing={activatePolygonDrawing}
                                    polygonCoordinates={polygonCoordinates}
                                />
                            </Box>
                        </ErrorBoundary>
                    </Grid>
                )}
                {isSidePanelOpen && (
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{ backgroundColor: '#f5f5f5', overflowY: 'auto', position: 'relative', height: '100%' }}
                    >
                            <SidePanelComponent
                            isSidePanelOpen={isSidePanelOpen}
                            setIsSidePanelOpen={setIsSidePanelOpen}
                            year={year}
                            setYear={setYear}
                            setSelectedMarker={setSelectedMarker}
                            selectedAddressLatLng={selectedAddressLatLng}
                            setSelectedAddressLatLng={setSelectedAddressLatLng}
                            setShowContribution={setShowContribution}
                            setSelectedAddress={setSelectedAddress}
                            markerIds={selectedMarkerIds}
                            refreshData={fetchLocations}
                            setIsEditing={setIsEditing}
                            isEditing={isEditing}
                            addStoryMode={addStoryMode}
                            setAddStoryMode={setAddStoryMode}
                            polygonData={polygonData}
                            selectedAddress={selectedAddress}
                            />
                    </Grid>
                )}
            </Grid>
        </Box>
    );

}

export default MapComponent;
