import axios from 'axios';

const FormsAPI = {
    getForms: () => axios.get('https://api.whoseland.work/FormHandler.php?action=getForms'),
    getFormsProject: (projectsId) => axios.get(`https://api.whoseland.work/FormHandler.php?action=getFormsProject&projectsId=${projectsId}`), // New method
    getFields: (projectsId) => axios.get(`https://api.whoseland.work/FormHandler.php?action=getFields&projectsId=${projectsId}`),
    saveForm: (form) => axios.post('https://api.whoseland.work/FormHandler.php?action=saveForm', form),
    createField: (field) => axios.post('https://api.whoseland.work/FormHandler.php?action=createField', field),
    getProjects: () => axios.get('https://api.whoseland.work/FormHandler.php?action=getProjects')
};

export default FormsAPI;
